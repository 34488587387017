import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */

export var RetailMediaPriceTagDataFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment RetailMediaPriceTagData on PriceTag {\n  priceDiscount {\n    label\n    percentageLabel\n    endDate\n  }\n  topLabel\n  priceLabel {\n    wasPrice {\n      amount\n    }\n    price {\n      amount\n    }\n    saving {\n      label\n      savingAmount {\n        amount\n      }\n    }\n  }\n  clubSellPrice {\n    amount\n  }\n  clubExclusive\n  enabled\n  retailPriceDisclaimer {\n    description\n  }\n}\n    "])));
export var RetailMediaProductCardDataFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment RetailMediaProductCardData on Product {\n  __typename\n  id\n  title\n  ratingsSummary {\n    numberOfRatings\n    averageRating\n  }\n  assets {\n    hero {\n      url\n      altText\n    }\n  }\n  brand {\n    name\n    slug\n  }\n  priceTag {\n    ...RetailMediaPriceTagData\n  }\n  selectedOffer {\n    id\n    clubExclusive\n    clubFreeShipping\n    freeShipping\n    sellPrice {\n      amount\n    }\n    seller {\n      id\n      name\n    }\n    badges\n    priceTag {\n      ...RetailMediaPriceTagData\n    }\n    salesRuleOffers {\n      id\n      label\n      eventId\n      eventSlug\n      disclaimerTitle\n    }\n  }\n  options {\n    name\n  }\n  sellable\n  slug\n  adult\n}\n    ", ""])), RetailMediaPriceTagDataFragmentDoc);
export var RetailMediaProductsDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query RetailMediaProducts($inventoryId: String!, $limit: Int, $searchQuery: String, $snowplowSessionId: String, $customerUUID: String, $facets: FacetInput) {\n  retailMediaProducts(\n    input: {inventoryId: $inventoryId, snowplowSessionId: $snowplowSessionId, searchQuery: $searchQuery, limit: $limit, customerUUID: $customerUUID, facets: $facets}\n  ) {\n    products {\n      ...RetailMediaProductCardData\n    }\n    tracking {\n      id\n      clickTrackingUrl\n      impressionTrackingUrl\n      productId\n    }\n  }\n}\n    ", ""])), RetailMediaProductCardDataFragmentDoc);
/**
 * __useRetailMediaProductsQuery__
 *
 * To run a query within a React component, call `useRetailMediaProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailMediaProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailMediaProductsQuery({
 *   variables: {
 *      inventoryId: // value for 'inventoryId'
 *      limit: // value for 'limit'
 *      searchQuery: // value for 'searchQuery'
 *      snowplowSessionId: // value for 'snowplowSessionId'
 *      customerUUID: // value for 'customerUUID'
 *      facets: // value for 'facets'
 *   },
 * });
 */

export function useRetailMediaProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RetailMediaProductsDocument, options);
}
export function useRetailMediaProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RetailMediaProductsDocument, options);
}
export function useRetailMediaProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RetailMediaProductsDocument, options);
}
export var CategoryNavDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    query CategoryNav($domain: String!) {\n  cmsConfiguration {\n    header(input: {domain: $domain}) {\n      megaMenu {\n        navData {\n          shops {\n            id\n            name\n            url\n            image\n          }\n        }\n      }\n    }\n  }\n}\n    "])));
/**
 * __useCategoryNavQuery__
 *
 * To run a query within a React component, call `useCategoryNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryNavQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */

export function useCategoryNavQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CategoryNavDocument, options);
}
export function useCategoryNavLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CategoryNavDocument, options);
}
export function useCategoryNavSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CategoryNavDocument, options);
}
export var TrendingSearchTermsDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query TrendingSearchTerms($limit: Int!) {\n  trendingSearchTerms(input: {limit: $limit}) {\n    terms\n  }\n}\n    "])));
/**
 * __useTrendingSearchTermsQuery__
 *
 * To run a query within a React component, call `useTrendingSearchTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingSearchTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingSearchTermsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useTrendingSearchTermsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(TrendingSearchTermsDocument, options);
}
export function useTrendingSearchTermsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(TrendingSearchTermsDocument, options);
}
export function useTrendingSearchTermsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(TrendingSearchTermsDocument, options);
}
export var CategoryFeaturedEventDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    query CategoryFeaturedEvent($eventId: String!) {\n  eventBySlug(input: {slug: $eventId}) {\n    event {\n      id\n      title\n      url\n      image {\n        single {\n          id\n          url\n          altText\n          width\n          height\n        }\n      }\n    }\n  }\n}\n    "])));
/**
 * __useCategoryFeaturedEventQuery__
 *
 * To run a query within a React component, call `useCategoryFeaturedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryFeaturedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryFeaturedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */

export function useCategoryFeaturedEventQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CategoryFeaturedEventDocument, options);
}
export function useCategoryFeaturedEventLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CategoryFeaturedEventDocument, options);
}
export function useCategoryFeaturedEventSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CategoryFeaturedEventDocument, options);
}
export var BrandRedirectsDocument = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    query BrandRedirects {\n  brandRedirects {\n    __typename\n    redirects {\n      brandSlug\n      targetPath\n    }\n  }\n}\n    "])));
/**
 * __useBrandRedirectsQuery__
 *
 * To run a query within a React component, call `useBrandRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandRedirectsQuery({
 *   variables: {
 *   },
 * });
 */

export function useBrandRedirectsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(BrandRedirectsDocument, options);
}
export function useBrandRedirectsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(BrandRedirectsDocument, options);
}
export function useBrandRedirectsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(BrandRedirectsDocument, options);
}