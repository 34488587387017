import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Box, Button, PageContainer } from "@catchoftheday/cg-components";
import { GlobalContextProvider, ScrollToTop, SponsoredDisclaimerModal, URL_CATCH_LOGO_SEO } from "@catchoftheday/common-vendors";
import { Footer, Header } from "@catchoftheday/header";
import { styled, ThemeProvider } from "@catchoftheday/theme";
import { cleanHostName } from "@catchoftheday/utilities";
import { ProductPageDescription } from "@search-app/components/ProductPageDescription";
import { TacticalCategoryContextProvider } from "@search-app/contexts/TacticalCategoryContext";
import { Search } from "../components/Search";
import { useShallowSelector } from "../store";
import { AdultBlurContainer, AdultPopup } from "./AdultPopUp";
import { ErrorLoading } from "./ErrorLoading";
import { TacticalCategory } from "./TacticalCategory";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SkipContent = styled(Button.withComponent("a", process.env.NODE_ENV === "production" ? {
  target: "ehpiedz0"
} : {
  target: "ehpiedz0",
  label: "SkipContent"
}))(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: -100;\n  &:focus,\n  &:active {\n    text-decoration: underline;\n    z-index: 100;\n  }\n"])));

var BasePage = function BasePage(_ref) {
  var host = _ref.host,
      isSearchResultsHidden = _ref.isSearchResultsHidden,
      isTacticalCategoryPage = _ref.isTacticalCategoryPage;

  var _useShallowSelector = useShallowSelector(function (state) {
    return {
      title: state.seo.metaTitle,
      error: state.search.error,
      description: state.seo.metaDescription,
      onClientRender: !state.search.isInitialSearch
    };
  }),
      error = _useShallowSelector.error,
      title = _useShallowSelector.title,
      description = _useShallowSelector.description,
      onClientRender = _useShallowSelector.onClientRender;

  var _useRouter = useRouter(),
      asPath = _useRouter.asPath;

  var metaTitle = [title, cleanHostName(host, true)].filter(Boolean).join(" | ");
  var slug = useRef(asPath.replace(/\?.*/, ""));
  useEffect(function () {
    slug.current = true ? window.location.pathname : "";
  }, []);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Head, null, onClientRender ? ___EmotionJSX("meta", {
    name: "description",
    content: description
  }) : null, ___EmotionJSX("meta", {
    property: "og:image",
    content: URL_CATCH_LOGO_SEO
  }), metaTitle ? ___EmotionJSX("title", null, metaTitle) : null), ___EmotionJSX(ThemeProvider, null, ___EmotionJSX(GlobalContextProvider, null, ___EmotionJSX(TacticalCategoryContextProvider, {
    isTacticalCategoryPage: isTacticalCategoryPage,
    isSearchResultsHidden: isSearchResultsHidden,
    slug: slug.current
  }, ___EmotionJSX(SkipContent, {
    href: "#maincontent",
    textTransform: "none",
    variant: "tertiary",
    position: "absolute",
    zIndex: 4,
    color: "black",
    p: 3,
    top: 3,
    left: 3
  }, "Skip to Main Content"), ___EmotionJSX(AdultPopup, null), ___EmotionJSX(SponsoredDisclaimerModal, null), ___EmotionJSX(AdultBlurContainer, null, ___EmotionJSX(Header, {
    host: host
  }), isTacticalCategoryPage && ___EmotionJSX(TacticalCategory, null), !isSearchResultsHidden && ___EmotionJSX(PageContainer, {
    pl: {
      xs: "0",
      lg: "20px"
    }
  }, !title && error ? ___EmotionJSX(ErrorLoading, null) : ___EmotionJSX(Box, {
    maxWidth: "1340px",
    margin: "auto",
    pt: {
      xs: "0",
      md: "40px"
    },
    pb: "40px"
  }, ___EmotionJSX(Search, {
    host: host,
    isSearchPage: slug.current === "/search"
  }), onClientRender ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ProductPageDescription, null)) : null)), ___EmotionJSX(Footer, null)), ___EmotionJSX(ScrollToTop, null)))));
};

export default BasePage;